.main {
  width: 90%;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 1rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
  align-items: flex-start;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 10px;
  text-align: center;
}

.greeting-text {
  margin-top: 0px;
  font-size: 100px;
  margin-bottom: 0px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
}
.greeting-sub {
  margin-top: 0px;
  font-size: 60px;
  margin-bottom: 50px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
}
.greeting-typewriter {
  margin-top: 0px;
  font-size: 60px;
  margin-bottom: 60px;
  line-height: 0px;
  font-family: "Google Sans Regular";
}

.greeting-text-span {
  font-size: 18px;
  margin-top: 65px;
  margin-right: 30px;
  font-family: "Google Sans Medium";
}

/* .greeting-image-div > * {
  max-width: 100%;
  height: auto;
} */

.greeting-image-div > * {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the images vertically */
  justify-content: space-around; /* Distributes space around images */
  width: 100%; /* Full width to accommodate child flex settings */
}

.image-container {
  display: flex;
  flex-direction: column; /* Stacks the images vertically */
  align-items: center; /* Center align the images horizontally */
  width: 100%; /* Ensures the container takes the full width */
}

.image {
  width: 70%; /* Sets a max width for all images to be uniform */
  height: auto; /* Maintains the aspect ratio of the images */
  display: block; /* Ensures images are treated as block elements */
  margin: 10px auto; /* Additional measure to center images */
  border-radius: 10px; /* Optional: for rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); /* Optional: for shadow effect */
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-span {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 60px;
    margin-top: 50px;
  }
  .greeting-sub {
    margin-top: 10px;
    font-size: 30px;
    margin-bottom: 0px;
    line-height: 1.1;
    font-family: "Google Sans Bold";
  }
  .greeting-typewriter {
    margin-top: 30px;
    font-size: 30px;
    margin-bottom: 100px;
  }
  .greeting-text-span {
    font-size: 18px;
    margin-right: 0px;
  }

  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    margin-top: 200px;
    display: flex;
    justify-content: center;
  }
  .button-greeting-div {
    display: flex;
    margin-top: 10px;
  }
}
@media (max-width: 480px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 50px;
    margin-top: -50px;
  }
  .greeting-sub {
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 0px;
    line-height: 1.1;
    font-family: "Google Sans Bold";
  }

  .greeting-typewriter {
    margin-top: 20px;
    font-size: 25px;
    margin-bottom: 40px;
  }
  .greeting-text-span {
    font-size: 15px;
    margin-right: 0px;
    line-height: normal;
    margin-bottom: 0px;
  }
  .greeting-main {
    display: block;
  }

  .button-greeting-div {
    display: flex;
    margin-top: -10px;
    text-align: center;
  }
}

.entities {
  line-height: 2;
}

[data-entity] {
  padding: 0.25em 0.35em;
  margin: 0px 0.25em;
  line-height: 1;
  display: inline-block;
  border-radius: 0.25em;
  border: 1px solid;
  background: transparent;
}

/* [data-entity]::after {
  box-sizing: border-box;
  content: attr(data-entity);
  font-size: 0.6em;
  line-height: 1;
  padding: 0.35em;
  border-radius: 0.35em;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 0px 0.1rem 0.5rem;
} */

[data-entity][data-entity="skill"] {
  background: rgba(166, 226, 45, 0.2);
  border-color: rgb(166, 226, 45);
  color: rgb(255, 255, 255);
}
[data-entity][data-entity="skill"]::after {
  background: rgb(166, 226, 45);
  color: rgb(0, 0, 0);
}

[data-entity][data-entity="person"] {
  background: rgba(45, 226, 217, 0);
  border-color: rgb(45, 226, 141);
  color: rgb(255, 255, 255);
}

[data-entity][data-entity="person"]::after {
  background: rgb(45, 226, 141);
  color: rgb(0, 0, 0);
}

[data-entity][data-entity="passion"] {
  background: rgb(187, 47, 117, 0.2);
  border-color: rgb(187, 47, 117);
  color: rgb(255, 255, 255);
}

[data-entity][data-entity="passion"]::after {
  background: rgb(187, 47, 117);
}

[data-entity][data-entity="diploma"] {
  background: rgba(47, 136, 187, 0.2);
  border-color: rgb(47, 136, 187);
  color: rgb(255, 255, 255);
}

[data-entity][data-entity="diploma"]::after {
  background: rgb(47, 136, 187);
  /* color: rgb(0, 0, 0); */
}

[data-entity][data-entity="objective"] {
  background: rgba(255, 204, 0, 0.2);
  border-color: rgb(255, 179, 0);
  color: rgb(255, 255, 255);
}

[data-entity][data-entity="objective"]::after {
  background: rgb(255, 179, 0);
  color: rgb(0, 0, 0);
}

[data-entity][data-entity="hobby"] {
  background: rgba(255, 0, 0, 0.2);
  border-color: rgb(255, 57, 57);
  color: rgb(255, 255, 255);
}

[data-entity][data-entity="hobby"]::after {
  background: rgb(255, 57, 57);
  color: rgb(0, 0, 0);
}
